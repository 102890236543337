import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field, FormikProvider, useFormik } from "formik";
import IsrInput from "../../IsrInput/IsrInput";

const NewsPostsFilterContainer = ({
  categories,
  setFilteringOptions,
  selectedTag,
}) => {
  const filteringForm = useFormik({
    initialValues: {
      tags: {
        label: selectedTag ? selectedTag.label : "All tags",
        value: selectedTag ? selectedTag.value : -1,
      },
      dates: { label: "Newest", value: 1 },
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    setFilteringOptions(filteringForm.values);
  }, [filteringForm.values]);

  return (
    <FormikProvider value={filteringForm}>
      <form onSubmit={filteringForm.handleSubmit}>
        <Field
          name="tags"
          id="tags"
          variant="select"
          classNamePrefix="isr"
          component={IsrInput}
          placeholder="Tags"
          options={categories}
        />
        <Field
          name="dates"
          id="dates"
          variant="select"
          classNamePrefix="isr"
          component={IsrInput}
          placeholder="Dates"
          options={[
            { label: "From oldest", value: 0 },
            { label: "Newest", value: 1 },
          ]}
        />
      </form>
    </FormikProvider>
  );
};

NewsPostsFilterContainer.defaultProps = {
  categories: [],
  selectedTag: null,
};

NewsPostsFilterContainer.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape),
  setFilteringOptions: PropTypes.func.isRequired,
  selectedTag: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default NewsPostsFilterContainer;
