import { useStaticQuery, graphql } from "gatsby";

export const useNewsMediaCenterSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page News" } }) {
        newsPage {
          mediaCenterSection {
            copy
            heading
            buttonLink
          }
        }
      }
    }
  `);
  return data.wpPage.newsPage.mediaCenterSection;
};
