/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import IsrLabel from "../../IsrLabel/IsrLabel";
import IsrLink from "../../IsrLink/IsrLink";

import Spacer from "../../Spacer/Spacer";

const NewsPostItem = ({ news, setSelectedTag }) => {
  const author = news.newsSinglePost.publicationAuthor
    ? `by ${news.newsSinglePost.publicationAuthor}`
    : "";
  const date = news.newsSinglePost.publicationDate || news.date;
  const link = news.newsSinglePost.externalLink || news.link;
  const title = news.newsSinglePost.publicationTitle || news.title;
  const categories = news.newsCategories.nodes;
  return (
    <div className="news">
      <div className="newsContentContainer">
        <div className="newsType">
          <IsrLink
            link={{ url: link, target: "_blank" }}
            classNames="news-title"
          >
            {news.title}
          </IsrLink>
          <div>
            {categories.map((category) => {
              return (
                <IsrLabel
                  clickAction={() =>
                    setSelectedTag({
                      label: category.name,
                      value: category.id,
                    })
                  }
                  key={category.id}
                  variant="secondary"
                  color="grey"
                  size="md"
                >
                  {category.name}
                </IsrLabel>
              );
            })}
          </div>
        </div>
        <div className="newsDetails">
          <IsrLink link={{ url: link, target: "_blank" }}>{title}</IsrLink>
          <p>{author}</p>
          <p>{date}</p>
        </div>
      </div>
      <Spacer mobileSize={15} size={30} />
      <hr size="3" />
      <Spacer mobileSize={15} size={30} />
    </div>
  );
};

NewsPostItem.propTypes = {
  news: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
    link: PropTypes.string,
    newsSinglePost: PropTypes.shape({
      publicationAuthor: PropTypes.string,
      publicationDate: PropTypes.string,
      externalLink: PropTypes.string,
      publicationTitle: PropTypes.string,
    }),
    newsCategories: PropTypes.shape({ nodes: PropTypes.array }),
    author: PropTypes.shape({
      node: PropTypes.shape({ name: PropTypes.string }),
    }),
  }).isRequired,
  setSelectedTag: PropTypes.func.isRequired,
};

export default NewsPostItem;
