import { useStaticQuery, graphql } from "gatsby";

export const useNewsHeadingSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page News" } }) {
        newsPage {
          newsPageHeadingSection {
            heading
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, height: 300, width: 1440)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.newsPage.newsPageHeadingSection;
};
