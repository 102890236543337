/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { orderBy, uniqueId } from "lodash";
import Spacer from "../../Spacer/Spacer";
import NewsPostsFilterContainer from "./NewsPostsFilterContainer";
import NewsPostItem from "./NewsPostItem";
import NewsPostPagination from "./NewsPostPagination/NewsPostPagination";
import { useNewsPostsStatic } from "../../../hooks/useNewsPosts/useNewsPostsStatic";
import { useNewsCategories } from "../../../hooks/useNewsPosts/useNewsCategories";

const NewsPostsContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteringOptions, setFilteringOptions] = useState({
    tags: {
      label: "All Tags",
      value: -1,
    },
    dates: { label: "Newest", value: 1 },
  });

  const setSelectedTag = (selected) => {
    setFilteringOptions({
      ...filteringOptions,
      tags: selected,
    });
  };

  const newsPosts = useNewsPostsStatic();
  const newsPostsCategories = useNewsCategories();

  let filteredPosts = newsPosts;

  const postsPerPage = 10;
  let totalPages = 0;

  if (filteringOptions.tags.value !== -1) {
    filteredPosts = filteredPosts.filter((item) =>
      item.newsCategories.nodes.some(
        (e) => e.id === filteringOptions.tags.value
      )
    );
  }
  if (filteringOptions.dates.value != 1) {
    filteredPosts = orderBy(
      filteredPosts,
      (item) => item.newsSinglePost.publicationDate || item.date,
      ["asc"]
    );
  }
  totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  return (
    <>
      <NewsPostsFilterContainer
        setFilteringOptions={setFilteringOptions}
        selectedTag={filteringOptions.tags}
        categories={[
          {
            label: "All Tags",
            value: -1,
          },
          ...newsPostsCategories,
        ]}
      />
      <Spacer mobileSize={25} size={50} />
      {filteredPosts instanceof Array ? (
        <div className="newsContainer">
          {filteredPosts.length === 0 && (
            <h3 className="no-data">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-info-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </svg>
              There are no news currently
            </h3>
          )}
          {filteredPosts
            .map((news) => {
              return (
                <NewsPostItem
                  setSelectedTag={setSelectedTag}
                  key={uniqueId("newspost-id")}
                  news={news}
                />
              );
            })
            .slice(
              (currentPage - 1) * postsPerPage,
              currentPage * postsPerPage
            )}
        </div>
      ) : (
        <div className="col-12">
          <div className="loader">
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      )}
      <NewsPostPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
      />
    </>
  );
};

export default NewsPostsContainer;
