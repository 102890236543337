import { useStaticQuery, graphql } from "gatsby";

export const useNewsCategories = () => {
  const data = useStaticQuery(graphql`
    {
      allWpNewsCategory {
        nodes {
          id
          name
        }
      }
    }
  `);
  return data.allWpNewsCategory.nodes.map((cat) => ({
    value: cat.id,
    label: cat.name,
  }));
};
