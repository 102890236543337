import React from "react";
import Proptypes from "prop-types";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { makeStyles } from "@material-ui/core/styles";
import "./NewsPostPagination.scss";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-textPrimary.Mui-selected": {
      backgroundColor: "#70e8cb",
      color: "#000000",
    },
    "& .MuiPaginationItem-textPrimary.Mui-selected:hover, & .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible":
      {
        backgroundColor: "#70e8cb !important",
      },
  },
}));

const NewsPostPagination = ({ currentPage, totalPages, onPageChange }) => {
  const classes = useStyles();
  return (
    <section className="newsPost-pagination">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="pagination">
              {totalPages > 1 && (
                <Pagination
                  classes={{ ul: classes.ul }}
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      onClick={() => onPageChange(item.page)}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

NewsPostPagination.protoTypes = {
  currentPage: Proptypes.number.isRequired,
  totalPages: Proptypes.number.isRequired,
  onPageChange: Proptypes.func.isRequired,
};

export default NewsPostPagination;
