import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import NewsSection from "../components/News/NewsSection";
import Footer from "../components/Footer/Footer";

const News = () => {
  return (
    <Layout>
      <Header />
      <NewsSection />
      <Footer />
    </Layout>
  );
};

export default News;
