import React from "react";
import { useNewsHeadingSection } from "../../hooks/useNewsPosts/useNewsHeadingSection";
import { useNewsMediaCenterSection } from "../../hooks/useNewsPosts/useNewsMediaCenterSection";
import IsrLink from "../IsrLink/IsrLink";
import Jumbotron from "../Jumbotron/Jumbotron";
import Spacer from "../Spacer/Spacer";
import IsrButton from "../IsrButton/IsrButton";
import NewsPostsContainer from "./components/NewsPostsContainer";
import "./NewsSection.scss";

const NewsSection = () => {
  const { heading, backgroundImage } = useNewsHeadingSection();
  const {
    copy,
    heading: mediaHeading,
    buttonLink,
  } = useNewsMediaCenterSection();
  return (
    <section className="newsSection">
      <Jumbotron media={backgroundImage} title={heading} />
      <div className="container">
        <Spacer mobileSize={25} size={50} />
        <NewsPostsContainer />
        <Spacer mobileSize={20} size={40} />
        <div className="mediaCenterContainer">
          {mediaHeading && <h2>{mediaHeading}</h2>}
          <div>
            <span
              className="media-div"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
            <IsrButton type="button" variant="primary" size="md">
              <IsrLink link={{ url: buttonLink || "#" }}>View Page</IsrLink>
            </IsrButton>
          </div>
        </div>
        <Spacer mobileSize={75} size={150} />
      </div>
    </section>
  );
};

export default NewsSection;
