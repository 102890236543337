import { useStaticQuery, graphql } from "gatsby";

export const useNewsPostsStatic = () => {
  const data = useStaticQuery(graphql`
    {
      allWpNews {
        nodes {
          title
          author {
            node {
              firstName
              lastName
              name
            }
          }
          date(formatString: "MMMM DD, YYYY")
          link
          newsCategories {
            nodes {
              link
              name
              id
            }
          }
          newsSinglePost {
            publicationAuthor
            publicationDate
            publicationTitle
            externalLink
          }
        }
      }
    }
  `);
  return data.allWpNews.nodes;
};
